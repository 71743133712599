'use strict';
/**
 * !! Important Note
 * If your project required to work with IE11.
 * Please add `ie11` to `supportTarget` in `node_scripts/config.js`
 * In this way, ESBuild will use babel to transform script to ES5 before compile.
 *
 * !! Please note that ESBuild still do not support ES6 `let` statement declares.
 */

/**
 * @typedef {import('jquery')} jQuery
 * @typedef {import('jquery')} $
 * @typedef {import('bootstrap') } bootstrap
 * @typedef {import('moment') } moment
 * @typedef {import('flatpickr') } flatpickr
 * @typedef {import('select2') } select2
 * @typedef {import('sortablejs') } Sortable
 * @typedef {import('tinymce')} tinymce
 * @typedef {import('@yaireo/tagify')} Tagify
 */

document.addEventListener('DOMContentLoaded', function () {
  // create easy initial for tinymce, since jquery.tinymce.min.js not working
  if (typeof tinyMCE === 'object') {
    jQuery.fn.extend({
      tinymce: function (opt) {
        var baseUrl = $('base[href]').attr('href'),
            opt = $.extend(typeof opt != 'undefined' ? opt : {}, {
          inline_styles: true,
          height: 300,
          forced_root_block: 'p',
          plugins: ['advlist link image imagetools lists preview hr anchor', 'searchreplace visualblocks visualchars code fullscreen media', 'table template paste help'],
          toolbar: 'code | undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | ' + 'bullist numlist outdent indent | link image media',
          menubar: 'view edit insert format table help',
          menu: {
            view: {
              title: 'View',
              items: 'visualaid visualchars visualblocks | spellchecker | preview | fullscreen'
            },
            edit: {
              title: 'Edit',
              items: 'undo redo | cut copy paste | selectall | searchreplace'
            },
            insert: {
              title: 'Insert',
              items: 'hr image link anchor media inserttable | template'
            },
            format: {
              title: 'Format',
              items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats align | forecolor backcolor | removeformat'
            },
            table: {
              title: 'Table',
              items: 'inserttable | cell row column | tableprops deletetable'
            },
            help: {
              title: 'Help',
              items: 'help'
            }
          },
          // == Custom Stylesheet
          content_css: [baseUrl + 'third-party/tinymce/skins/content/default/content.css', // tinymce default styles
          baseUrl + 'assets/frontend/lib.min.css', // load frontend style for bootstrap
          baseUrl + 'assets/frontend/app.min.css', // load frontend style for component styles
          baseUrl + 'third-party/tinymce/custom.css' // custom style for this project
          ],
          // == Template
          templates: baseUrl + 'third-party/tinymce/template.json',
          custom_elements: 'youtube,matterport',
          // Custom element demo
          // == Image Plugin
          image_advtab: true,
          image_dimensions: false,
          // image_class_list: [],
          // images_upload_handler: function(blobInfo, success, failure, progress) {},
          file_picker_types: 'image',
          file_picker_callback: function (callback, value, meta) {
            var input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.onchange = function () {
              var file = this.files[0],
                  reader = new FileReader();

              reader.onload = function () {
                var id = 'blobid' + new Date().getTime(),
                    blobCache = tinymce.activeEditor.editorUpload.blobCache,
                    base64 = reader.result.split(',')[1],
                    blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);
                callback(blobInfo.blobUri(), {
                  title: file.name
                });
              };

              reader.readAsDataURL(file);
            };

            input.click();
          },
          // == Media Plugin
          media_poster: false,
          audio_template_callback: function (data) {
            return '<audio controls>' + '\n<source src="' + data.source + '"' + (data.sourcemime ? ' type="' + data.sourcemime + '"' : '') + ' />\n' + (data.altsource ? '<source src="' + data.altsource + '"' + (data.altsourcemime ? ' type="' + data.altsourcemime + '"' : '') + ' />\n' : '') + '</audio>';
          },
          video_template_callback: function (data) {
            return '<div class="ratio ratio-16x9"><video width="100%" height="100%"' + (data.poster ? ' poster="' + data.poster + '"' : '') + ' controls="controls">\n' + '<source src="' + data.source + '"' + (data.sourcemime ? ' type="' + data.sourcemime + '"' : '') + ' />\n' + (data.altsource ? '<source src="' + data.altsource + '"' + (data.altsourcemime ? ' type="' + data.altsourcemime + '"' : '') + ' />\n' : '') + '</video></div>';
          }
        });
        this.each(function () {
          var $textarea = $(this),
              thisOpt = {
            target: this
          };

          if ($textarea.attr('data-images-upload-url')) {
            thisOpt.relative_urls = false;
            thisOpt.remove_script_host = false;
            thisOpt.convert_urls = true;
            thisOpt.images_upload_url = $textarea.attr('data-images-upload-url');
          }

          Object.assign(thisOpt, opt);
          tinymce.init(thisOpt);
        });
      }
    });
  } // create easy initial for tinymce, since jquery.tinymce.min.js not working


  if (typeof JSONEditor === 'function') {
    jQuery.fn.extend({
      jsonEditor: function (opt) {
        var opt = $.extend(typeof opt != 'undefined' ? opt : {}, {});
        this.each(function () {
          var $textarea = $(this),
              $container = $('<div />').attr({
            'data-name': $textarea.attr('name')
          }),
              jsonStr = $textarea.text() || null,
              thisOpt = {};
          Object.assign(thisOpt, opt);

          thisOpt.onChangeText = function (jsonString) {
            $textarea.val(jsonString);
          };

          $container.insertAfter($textarea);
          $textarea.removeAttr('class').css({
            'height': '1px',
            'min-height': 'auto',
            'overflow': 'hidden',
            'opacity': '0.01'
          });
          return new JSONEditor($container.get(0), thisOpt, JSON.parse(jsonStr));
        });
      }
    });
  } // setup tooltip


  $('[data-bs-toggle="tooltip"]').each(function () {
    return new bootstrap.Tooltip(this);
  }); // setup popover

  $('[data-bs-toggle="popover"]').each(function () {
    return new bootstrap.Popover(this);
  }); // initial .cmssuite-form

  var $cmssuiteForm = $('.cmssuite-form');

  if ($cmssuiteForm.length !== 0) {
    // -- initial TinyMCE
    if (jQuery.fn.tinymce) {
      $('textarea[data-mime="text/html"]', $cmssuiteForm).tinymce();
    } // -- initial JSONEditor


    if (jQuery.fn.jsonEditor) {
      $('textarea[data-mime="application/json"]').jsonEditor();
    } // -- initial Select2


    if (jQuery.fn.select2) {
      $('select:not(.native-select)', $cmssuiteForm).each(function () {
        var $select = $(this),
            optCnt = $('option', $select).length,
            inInputGroup = $select.parents('.input-group').length == 1;

        if (optCnt > 200) {
          return console.log('Select[name=' + $select.attr('name') + '] has to many options. Cannot be initialize.');
        }

        if (inInputGroup) {
          return console.log('Select[name=' + $select.attr('name') + '] in input-group. Not to initialize with select2.');
        }

        var opts = {
          width: '100%',
          placeholder: $select.attr('placeholder') || 'Select an option',
          allowClear: $select.find('option').eq(0).attr('value') == ''
        };

        if ($select.attr('data-src')) {
          opts.ajax = $select.attr('data-src');
        }

        $select.select2(opts);
      });
    } // -- initial Flatpickr


    if (jQuery.fn.flatpickr) {
      $('input[type=date]', $cmssuiteForm).flatpickr({
        enableTime: false,
        dateFormat: "Y-m-d"
      });
      $('input[type=datetime-local]', $cmssuiteForm).flatpickr({
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        time_24hr: true
      });
      $('input[type=time]', $cmssuiteForm).flatpickr({
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      });
      $('input[type=date-multiple]', $cmssuiteForm).flatpickr({
        mode: "multiple",
        dateFormat: "Y-m-d",
        conjunction: ','
      });
      $('input[type=date-range]', $cmssuiteForm).flatpickr({
        mode: "range",
        dateFormat: "Y-m-d"
      });
    } // -- initial Spectrum


    if (jQuery.fn.spectrum) {
      $('input[type=color]').each(function () {
        var $input = $(this),
            opt = {
          type: 'component',
          showInput: true,
          showInitial: true,
          showPalette: false,
          showButtons: false,
          showAlpha: false,
          allowEmpty: true,
          color: ''
        };

        if ($input.attr('data-palette')) {
          opt.showPalette = true;
          opt.palette = $input.attr('data-palette').split(',');
          if ($input.attr('data-palette-only')) opt.showPaletteOnly = true;
        }

        if ($input.attr('value')) opt.color = $input.attr('value');
        if ($input.attr('disabled')) opt.disabled = true;
        $input.attr('type', 'text').spectrum(opt);
      });
    } // -- Initial Tagify


    if (jQuery.fn.tagify) {
      $('.tagin').each(function () {
        new Tagify(this, {
          originalInputValueFormat: valArr => valArr.map(item => item.value).join(',')
        });
      });
    } // --intial input-uri


    $('[data-name=input-uri]').each(function () {
      if ($('button', this).length == 0) {
        $(this).append($('<button/>').addClass('btn btn-outline-primary').attr('type', 'button').html('<i class="fas fa-globe"></i>'));
      }
    }).on('click', 'button', function (e) {
      var $inputGroup = $(this).parent('[data-name=input-uri]'),
          url = $('select', $inputGroup).val() + '' + $('input[type=url]', $inputGroup).val();
      window.open(url, '_blank', 'noopener');
    }).on('paste', 'input[type=url]', function (e) {
      var $inputGroup = $(this).parent('[data-name=input-uri]'),
          catchPaste = function (evt, callback) {
        if (navigator.clipboard && navigator.clipboard.readText) {
          navigator.clipboard.readText().then(callback);
        } else if (evt.originalEvent && evt.originalEvent.clipboardData) {
          callback(evt.originalEvent.clipboardData.getData('text'));
        } else if (evt.clipboardData) {
          callback(evt.clipboardData.getData('text/plain'));
        } else if (window.clipboardData) {
          callback(window.clipboardData.getData('Text'));
        } else {
          setTimeout(function () {
            callback(elem.value);
          }, 100);
        }
      };

      catchPaste(e, function (clipData) {
        var $select = $('select', $inputGroup),
            valueProtocol = '',
            valueUri = '',
            option = [];
        $('option', $select).each(function () {
          option.push($(this).attr('value'));
        });

        for (var i = 0; i < option.length; i++) {
          if (clipData.startsWith(option[i])) {
            valueProtocol = option[i];
            valueUri = clipData.substr(valueProtocol.length);
          }
        }

        $('input[type=url]', $inputGroup).val(valueUri);
        $select.val(valueProtocol);
      });
    });
  } // initial .cmssuite-form


  var $cmssuiteSort = $('.cmssuite-sort');

  if ($cmssuiteSort.length !== 0 && window.Sortable) {
    // change sorting list to support drag & drop
    var $sortingList = $('.list-group', $cmssuiteSort);
    $sortingList.addClass('sortable');
    $($('<label />').addClass('sortable-helper').text('Drag and drop items to sort them in ascending order')).insertBefore($sortingList);
    $('.list-group-item input[type=number]', $sortingList).attr('type', 'hidden').before($('<i />').addClass('fas fa-align-justify sortable-handle'));
    $('.list-group-item', $sortingList).append($('<button />').addClass('sortable-to-top').attr({
      'type': 'button',
      'data-event': 'sort-to-top',
      'title': 'Jump to top'
    }).html($('<i />').addClass('fas fa-arrow-up'))); // initial drag & drop

    var $sortable = $('.sortable');
    $('.sortable').on('order-change', function () {
      $('input[type=hidden][name^=sort_ids]').each(function (index, el) {
        $(el).val(index);
      });
    }).on('click', '[data-event=sort-to-top]', function (e) {
      var $item = $(this).parents('.list-group-item'),
          $listGroup = $item.parents('.list-group');
      $item.prependTo($listGroup);
      $sortable.trigger('order-change');
    });
    new Sortable($sortable.get(0), {
      handle: '.sortable-handle',
      onChange: function (e) {
        $sortable.trigger('order-change');
      }
    });
  } // Maintain sideBar scroll position across page loads


  var $sideBarScroll = $('#sidebar > .collapse');

  if ($sideBarScroll.length !== 0) {
    var sideBarScrollKey = 'sidebar-scroll',
        sideBarCollapseKey = 'sidebar-collapse',
        sideBarCollapseStatus = {}; // recover last status

    sideBarCollapseStatus = localStorage.getItem(sideBarCollapseKey);
    sideBarCollapseStatus = sideBarCollapseStatus ? JSON.parse(sideBarCollapseStatus) : {};

    for (var collapseId in sideBarCollapseStatus) {
      if (sideBarCollapseStatus[collapseId]) {
        $('[aria-controls=' + collapseId + ']', $sideBarScroll).attr('aria-expanded', 'true');
        $('#' + collapseId).addClass('show');
      }
    }

    $sideBarScroll.scrollTop(localStorage.getItem(sideBarScrollKey) || 0); // save current status

    $(window).on('beforeunload', function () {
      $('[data-bs-toggle=collapse]', $sideBarScroll).each(function () {
        var $collapse = $(this);
        sideBarCollapseStatus[$collapse.attr('aria-controls')] = $collapse.attr('aria-expanded') === 'true';
      });
      localStorage.setItem(sideBarScrollKey, $sideBarScroll.scrollTop());
      localStorage.setItem(sideBarCollapseKey, JSON.stringify(sideBarCollapseStatus));
    });
  }
});